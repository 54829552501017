<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>

    <div class="content-container">
      
      <div class="left-bar ">
        <h1 class="page-title text-bold">论坛日程</h1>
        <div class="title text-bold">论坛</div>
        <div class="main-menu">
          <div class="row">
            <template v-for="(item, idx) in categorys">
              <div :key="idx" :class="['theme', categoryId == item.categoryId ? 'active' : '']" @click="switchCategory(item, idx)"><h1>{{item.categoryName}}</h1></div>
            </template>
          </div>
        </div>
      </div>
      
      <div>
        <h1 class="theme-title text-bold">论坛日程</h1>
        <div class="theme-content">
          <img :src="category.img" alt="">
        </div>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getCommonCategory } from '@/api/index.js';

export default {
  // name: '深圳国际家具展',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: '',
      categoryId: '',
      categorys: [],
      category: {},
    }
  },
  created() {
    this.categoryId = this.$route.query.categoryId || '';
    this.getCategorys();
  },
  methods: {
    switchCategory(item, idx) {
      this.categoryId = item.categoryId;
      this.category = this.categorys[idx];
    },
    getCategorys() {
      getCommonCategory().then(res => {
        let categorys = res.resultData || [];
        this.categorys = categorys;
        
        if (this.categorys.length) {
          if (this.categoryId) {
            this.category = this.categorys.find(e => e.categoryId == this.categoryId)
          } else{
            this.category = this.categorys[0];
            this.categoryId = this.category.categoryId
          }
          
        }
      })
    },
  },
}
</script>

<style lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .theme-content {
    img {
      display: block;
      width: 100%;
      margin: 5px auto;
      height: auto;
      border-radius: 4px;
    }
  }
}
// PC端
@media screen and (min-width: 1024px) {
  .theme-content {
    img {
      display: block;
      width: 90%;
      margin: 5px auto;
      height: auto;
      border-radius: 1px;
    }
  }
}
</style>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    
    .left-bar {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    .title {
      display: none;
    }
    
    .page-title {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }
    
    .main-menu {
      width: 100%;
      overflow-x: auto;
      
      &::-webkit-scrollbar {
        height: 0;
      }
      
      .row {
        width: auto;
        white-space: nowrap; 
        
        .theme {
          display: inline-block;
          width: 160px;
          cursor: pointer;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #fff;
          line-height: 30px;
          
          &.active {
            border-color: #060211;
          }
          h1 {
            font-weight: normal;
            font-size: 13px;
            font-family: 'SourceHanSansCN-Medium';
          }
          
        }
      }
      
    }
    
    .theme-title {
      display: none;
    }
    .theme-content {
      width: 375px;
      padding: 10px 17px;
      font-size: 14px;
      
      img {
        display: block;
        width: 100%;
        margin: 5px auto;
        height: auto;
      }
    }
  }
  
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    display: flex;
    width: 280px;
    margin: 0 auto 20px;
    
    .page-title {
      display: none;
    }
    
    .left-bar {
      width: 80px;
      padding: 10px;
      box-sizing: border-box;
      
      .title {
        font-size: 6.5px;
        margin-bottom: 7px;
        text-align: center;
        
      }
      .theme {
        width: 60px;
        margin: 2px 0;
        cursor: pointer;
        text-align: center;
        border-radius: 1px;
        border: 0.5px solid #fff;
        line-height: 12px;
        
        &.active,
        &:hover {
          border-color: #060211;
        }
        h1 {
          font-weight: normal;
          font-size: 4px;
          font-family: 'SourceHanSansCN-Medium';
        }
        
      }
      
      
    }
    .theme-title {
      font-size: 6px;
      text-align: center;
      margin-top: 10px;
    }
    
    .theme-content {
      width: 190px;
      margin-left: 10px;
      padding: 10px 0;
      font-size: 4.5px;
    }
  }
  
}

</style>
